import React, { useEffect, useState } from "react";
import "../styles/home.css";
import axios from "axios";
import avatar from "../avatar.png";
import { Link, useNavigate } from "react-router-dom";
import gem from "../gem.png";
import crown from "../crown.png";
import image from "../image.png";
import logout from "../logout.png";
import RulesModal from "./RulesModal.js";
import { useGlobalContext } from "../GlobalContext.js";

const LeaderboardTable = ({ leaderboardData }) => {
  return (
    <div className="leaderboard-container">
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Weekly</th>
            <th>Monthly</th>
            <th>All Time</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.allTime.map((item, index) => (
            <tr key={index}>
              <td>
                {index + 1}. {leaderboardData.weekly[index]?._id.username}
                <span className="text-sm">
                  {leaderboardData.weekly[index]?.totalScore
                    ? ` (${leaderboardData.weekly[index]?.totalScore})`
                    : ""}
                </span>
              </td>
              <td>
                {index + 1}. {leaderboardData.monthly[index]?._id.username}
                <span className="text-sm">
                  {leaderboardData.monthly[index]?.totalScore
                    ? ` (${leaderboardData.monthly[index]?.totalScore})`
                    : ""}
                </span>
              </td>
              <td>
                {index + 1}. {leaderboardData.allTime[index]?._id.username}
                <span className="text-sm">
                  {leaderboardData.allTime[index]?.totalScore
                    ? ` (${leaderboardData.allTime[index]?.totalScore})`
                    : ""}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DigitalHeroZone = ({ userData, email }) => {
  const points = userData.levelTotalScore;
  const username = userData.username;

  const curLevel = userData.level;
  const [leaderboard, setLeaderboard] = useState();
  const totalScore = userData.totalScore;

  const [rulesModal, setRulesModal] = useState(false);

  const showModal = () => {
    setRulesModal(true);
  };
  const hideModal = () => {
    setRulesModal(false);
  };

  const { globalState, setGlobalState } = useGlobalContext();

  useEffect(() => {
    const getTopics = async () => {
      try {
        const lboardData = await axios.post(
          `${process.env.REACT_APP_serverUrl}leaderboard`,
        );
        setLeaderboard(lboardData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    };

    getTopics();
  }, []);

  const handleCurtopic = (topicName) => {
    let selectedTopic;
    selectedTopic = userData.topics.find(
      (topic) => topic.topicName === topicName,
    );
    if (selectedTopic) {
      selectedTopic.levels.sort((a, b) => a.levelId - b.levelId);
      setGlobalState({
        ...globalState,
        topic: topicName,
      });
    }
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="container">
      <header>
        <div className="header-align">
          <Link to="#">
            <img src={avatar} alt="avatar" className="logo" />
          </Link>
          <h1>Digital Hero Zone</h1>
          <div className="logout-btn">
            <h3>Hello Young Adult! {userData.username?.split(" ")?.[0]}</h3>
            <img src={logout} alt="logout" onClick={handleLogout} />
          </div>
        </div>
        <div className="welcome-content">
          <h1 className="heading-mobile">Digital Hero Zone</h1>
          <p>Welcome to the Digital Battlefield!</p>
          <p>
            Level up your skills and unlock new challenges as you master the
            digital world
          </p>
        </div>
      </header>
      <div className="overflow-hidden bg-[#253439] text-white px-2 py-1 w-screen absolute left-0">
        <div
          className="whitespace-nowrap inline-block animate-[marquee_20s_linear_infinite] font-normal text-base"
          style={{ animation: "marquee 20s linear infinite" }}
        >
          Grab your chance to win cool prizes: Play the Quiz, Ace the Challenge
          and be in the Top Three of the leaderboard at the end of the month.
        </div>
      </div>
      <div className="w-full h-[42px]" />
      <div className="grid grid-gap-3">
        <div className="grid-2 grid-space">
          <div className="profile">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 s>🌟 Warrior {username}</h2>
              <div style={{ textAlign: "center" }}>
                <img src={crown} style={{ width: "30px", height: "40px" }} />
                <p>{totalScore}</p>
              </div>
            </div>

            <table>
              <tbody>
                <tr style={{ height: "50px" }}>
                  <td>Level</td>
                  <td>
                    <strong>{curLevel}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Points</td>
                  <td>
                    <strong>{points}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Gems</td>
                  <td>
                    <div className="flex items-center">
                      <span>{globalState.gems}</span>
                      <img src={gem} alt="Gem" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <p style={{ fontSize: "20px", margin: "10px 0" }}>
                Continue to play Quizzes and keep clearing the Levels.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 300,
                  marginTop: "20px",
                  cursor: "pointer",
                  textAlign: "left",
                  marginLeft: "-1.1vw",
                }}
              >
                Check <u onClick={showModal}>Rules</u> and get started
              </p>
              {rulesModal && <RulesModal closeModal={hideModal} />}
            </div>
          </div>
          <div>
            <p>
              Dive into fun quizzes to help out your friend Captain Diginaut as
              she takes on the villains of DigiVerse.
              <div style={{ marginBottom: "45px" }}>
                As you play, also learn you awesome Tech Tricks, Digital Good
                Habits & Safety tips!
              </div>
            </p>
            <div className="asidebtn">
              {Array.isArray(userData.topics) &&
                userData.topics.map((topic) => {
                  return (
                    <Link
                      to={`/quiz/${topic.levels[curLevel - 1].quizUrl}/${email}`}
                    >
                      <button
                        id={topic.topicName}
                        value={topic.topicName}
                        onClick={() => handleCurtopic(topic.topicName)}
                      >
                        {topic.topicName}
                      </button>
                    </Link>
                  );
                })}{" "}
            </div>
          </div>
        </div>
        <div className="grid-2 grid-space ">
          <div className="leaderboard-content">
            <h3>
              Can you top the leader board and show you're the ultimate digital
              champion
            </h3>
          </div>
          <div className="leaderboard">
            <h1 style={{ marginBottom: "30px" }}>Leaderboard</h1>
            {leaderboard && <LeaderboardTable leaderboardData={leaderboard} />}
          </div>
        </div>
      </div>
      <a target="_blank" href="https://forms.gle/zNSChvNRubefYaTM6">
        <div className="home-container">
          <u>Feedback Form</u>
        </div>
      </a>
    </div>
  );
};

export default DigitalHeroZone;
