import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Screen1 from "./screens/s1.js";
import SignUp from "./screens/signup.js";
import SignIn from "./screens/signin.js";
import Home from "./screens/home.js";
import HomeAdult from "./screens/home-adult.js";
import Quiz from "./screens/quiz.js";
import DigitalLiteracyPrograms from "./screens/packages.js";
import { useGlobalContext } from "./GlobalContext.js";
import SignupNew from "./screens/signup-new.js";
import axios from "axios";

const HomePage = () => {
  const [isLoading, setLoading] = useState(true);
  const [userData, setUserData] = useState();

  const { globalState, setGlobalState } = useGlobalContext();
  const email = globalState.email;

  useEffect(() => {
    (async function () {
      if (email) {
        const { data } = await axios.post(
          `${process.env.REACT_APP_serverUrl}userProfile/${email}`,
          { email: email },
        );
        setUserData(data);
        setLoading(false);
        setGlobalState({
          ...globalState,
          curLevel: data.level,
          username: data.username,
          totalScore: data.totalScore,
          gems: data.gemEarned,
        });
      }
    })();
  }, [email]);

  if (isLoading) {
    return (
      <div className="flex justify-center h-screen items-center">
        Loading...
      </div>
    );
  }

  return !userData.isAdult ? (
    <Home userData={userData} email={email} />
  ) : (
    <HomeAdult userData={userData} email={email} />
  );
};

const App = () => {
  const { globalState } = useGlobalContext();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Screen1 />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/signup-new" element={<SignupNew />} />
        <Route
          path="/quiz/:url/:email"
          element={
            globalState.isAuthenticated ? <Quiz /> : <Navigate to="/SignIn" />
          }
        />
        {/* <Route path="/screen1" element={globalState.isAuthenticated ? <Screen1 /> : <Navigate to="/SignIn" />} /> */}
        <Route
          path="/home"
          element={
            globalState.isAuthenticated ? (
              <HomePage email={globalState.email} />
            ) : (
              <Navigate to="/SignIn" />
            )
          }
        />
        {/* {!globalState.isAuthenticated && <Route path="*" element={<SignIn />} />} */}
        {/* <Route path="/home" element={ <Home />} /> */}
        {/* <Route path="/screen4" element={globalState.isAuthenticated ? <Screen4 /> : <Navigate to="/SignIn" />} /> */}
        <Route path="/packages" element={<DigitalLiteracyPrograms />} />

        {/* <Route path="/quiz/" element={<Quiz />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
