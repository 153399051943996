import React from "react";
import "../styles/quiz.css";
import avatar from "../avatar.png";
import { useGlobalContext } from "../GlobalContext.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import logout from "../logout.png";

function Quiz() {
  const param = useParams();
  const { globalState } = useGlobalContext();

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <div>
      <header>
        <div className="header-align">
          <Link to="/home">
            <img src={avatar} alt="avatar" className="logo" />
          </Link>
          <h1>Digital Hero Zone</h1>
          <div className="logout-btn">
            <h3>Hello! {globalState.username}</h3>
            <img src={logout} onClick={handleLogout} />
          </div>
        </div>
        <div className="welcome-content">
          <h1 className="heading-mobile">Digital Hero Zone</h1>
        </div>
      </header>
      <h2 style={{ textAlign: "center", marginBottom: "16px" }}>
        Play the <span className="highlight">{globalState.topic}</span> Quiz
      </h2>
      <div className="quiz-main-container">
        <div className="iframe-container">
          <div className="quiz-header"></div>
          <div className="quiz-container">
            <div className="quiz-interface">
              <iframe
                className="quiz-container"
                name="proprofs"
                id="proprofs"
                src={`https://www.proprofs.com/quiz-school/ugc/story.php?title=${param.url}&user_email=${param.email}&ew=430`}
                //   src={`${param.url}&user_email=${param.email}&ew=430`}

                allow="camera *;microphone *;fullscreen;"
                title="ProProfs Digital Code Quiz"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="quiz-footer">
          <p>
            Score as many points as you can, clear the Levels and Save the
            Library of Wisdom
            <br />
            You have <span className="points">
              {globalState.totalScore}
            </span>{" "}
            points
          </p>
          <p>Score 70%+ and earn a Gem for the topic</p>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
